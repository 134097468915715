.showcase{
    display: grid;
    grid-template-columns: 3fr 20%;
    width: 100%;
    height: 100vh;
    transition: 339ms;
}
.right_box{
    position: relative;
}
.showcase_img_box{
    background: #000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.showcase_img{
    width: 100%;
}
.right_gallery{
    height: 100vh;
    background: #F3F3F3;
    position: sticky;
    right: 0px;
    /* opacity: 0.5; */
    /* border-top-left-radius: 40px;
    border-bottom-left-radius: 40px; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    
    top: 0;
    transition: 339ms;
    
}
.showcase_text{
    color: #fff;
}
.bec{
    width: 34.09px;
    height: 57px;
    right: 0px;
    top: 150px;
    background: #131B28;
    border-radius: 0px 10px 10px 0px;
    position: fixed;
    transform: rotatez(180deg);
    transition: 339ms;

}
.hide {
    position: absolute;
    width: 34.09px;
    height: 57px;
    background: #131B28;
    border-radius: 0px 10px 10px 0px;
    left: 0px;
    top: 50px;
    transition: 339ms;
    cursor: pointer;
  
}
.right_gallery_box{
    width: 100%;
    height: 95vh;
    transform: 600ms;
    overflow-x: hidden;
    overflow-y: scroll;
}
.right_gallery_box::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.right_gallery_box::-webkit-scrollbar
{
  width: 0px;
  background-color: #F5F5F5;
}

.right_gallery_box::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}




.beak{
    position: fixed;
    bottom: 20px;
    left: 0;
    background:red;
    width: 158px;
    height: 50px;
    transition: 339ms;
    
}
.hidden{
    width: 0px;
    overflow: hidden;
    padding: 0;
}
.hide_img:hover{
    mix-blend-mode: exclusion;
}


.img_mini{
    width: auto;
    height: auto;
}