/* .preloader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

}

.spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  .leo {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.blue-orbit {
	width: 165px;
	height: 165px;
    border: 2px solid #66e6ff;
    -webkit-animation: spin3D 3s linear .2s infinite;
    animation: spin3D 3s linear .2s infinite;
}
.green-orbit {
	width: 120px;
	height: 120px;
  border: 2px solid #023371;
  -webkit-animation: spin3D 2s linear 0s infinite;
  animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
	width: 90px;
	height: 90px;
    border: 2px solid #66e6ff;
    -webkit-animation: spin3D 1s linear 0s infinite;
    animation: spin3D 1s linear 0s infinite;
}

@keyframes spin3D {
    from {
      transform: rotate3d(.5,.5,.5, 360deg);
    }
    to{
      transform: rotate3d(0deg);
    }
  }
  .white-orbit {
	width: 60px;
	height: 60px;
    border: 1px solid #2439fa;
    -webkit-animation: spin3D 10s linear 0s infinite;
    animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
} */

.preloader{
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: #023371;

  transition: 300ms;
  opacity: 1;
}
.preloader_fon{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 58px;
  opacity: 0.2;
}
.preloader_fon img {
  width: 18%;
  height: auto;
}
.preloader_fon img:nth-child(2) {
  width: 30%;
  height: auto;
}
.pl {
  --dur: 3s;
  margin: auto;
  position: relative;
  width: 26em;
  height: 20em;
}
.pl__nucleus,
.pl__nucleus-particle {
  position: absolute;
}
.pl__nucleus {
  top: 37%;
  left: 16%;
  transform-style: preserve-3d;
}
.pl__nucleus img{
  width: 400px;
  position: relative;
  left: -10px;
  height: auto;
}
.spinner {
  position: absolute;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 230px;
  height: 230px;
  left: 111px;
  border-radius: 50%;
  top: 106px;
  border: 20px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
  transform-origin: 50% 60%;
  transform: perspective(250px) rotateX(-3deg) scalex(0.2) scale(1.5);
  animation: spinner-wiggle 0.2s infinite;
}
.spinner_two {
  position: absolute;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 230px;
  height: 230px;
  left: 85px;
  border-radius: 50%;
  top: 65px;
  border: 20px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
  transform-origin: 50% 60%;
  transform: perspective(250px) rotateX(-3deg) rotate(119deg) scale3d(0.2, 1.7, 0.2);
  animation: spinner-wiggle 0.2s infinite;
}
.trree {
  transform: perspective(250px) rotateX(-3deg) rotate(243deg) scale3d(0.2, 1.8, 0.2);
  top: 65px;
  left: 132px;
}
.pl_box {
  position: absolute;
  left: 368px;
  top: 70px;
  transform: scale(0.3);
}
.spinner_two:before,
.spinner_two:after {
  content: "";
  position: absolute;
  margin: -20px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  /* opacity: .05; */
  border: inherit;
  border-color: transparent;
  animation: spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite
}
.spinner_two:before {
  border-top-color: #66e6ff;
}

.spinner_two:after {
  border-top-color: #66e6ff;
  animation-delay: 0.3s;
}
.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  margin: -20px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  /* opacity: .05; */
  border: inherit;
  border-color: transparent;
  animation: spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite
}

.spinner:before {
  border-top-color: #66e6ff;
}

.spinner:after {
  border-top-color: #66e6ff;
  animation-delay: 0.3s;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  20% {
    opacity: .1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: .1;
  }
}
.preloader.hidden{
  display: block;
  animation: hid 1.1s ;
  opacity: 0;

  display: none;
}
@keyframes hid {
  20% {
  display: block;

    opacity: 1;
  }
  40% {
  display: block;

    opacity: 0;
  }
  60% {
    display: none;
  }
}

@media only screen and (max-width : 670px) {
	.pl {
    --dur: 3s;
    margin: auto;
    position: relative;
    width: 37em;
    height: 20em;
  }
}
@media only screen and (max-width : 470px) {
	.pl {
    transform: scale(0.5);
  }
}