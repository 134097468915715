.header{
    background: #fff;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #F3F3F3;
}
.header_print {
    padding: 3px;
    border-radius: 22%;
    background: #fff;
    cursor: pointer;
}

.logo{
    width: auto;
    height: 100%;
}
.entrance{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 130%;
     */
    color: #FFFFFF;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 30px;
   
    background: #0A1420;
    border-radius: 7px;
    text-decoration: none;
}
/* переключатель */
.switch-btn {
    display: inline-block;
    width: 70px; /* ширина */
    height: 30px; /* высота */
    border-radius: 19px; /* радиус скругления */
    background: #DEDEDE; /* цвет фона */
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    transition-duration: 300ms; /* анимация */
  }
  .switch-btn::after {
    content: "";
    height: 24px; /* высота кнопки */
    width:24px; /* ширина кнопки */
    border-radius: 17px;
    background: #000000; /* цвет кнопки */
    top: 3px; /* положение кнопки по вертикали относительно основы */
    left: 3px; /* положение кнопки по горизонтали относительно основы */
    transition-duration: 300ms; /* анимация */
    position: absolute;
    z-index: 1;
  }
  .switch-on {
    background: #DEDEDE;
  }
  .switch-on::after {
    left: 42px;
    background: #ffffff; /* цвет кнопки */

  }
  .header_menu{
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 20px;
  }
.header_list{
  display: flex;
    justify-content: space-around;
    align-items: center;
    width: 55%;
}
.header_lists{
  position: relative;
  overflow: hidden;
  height: 20px;
}
/* .header_lists::after{
  content: "";
  display: block;
  position: absolute;
  
  top: 3px;
  right: -20px;

  width: 0; 
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #000;
} */
.header_lists p{
  cursor: pointer;
}
.popup_loginIn{
  width: 0vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right:  0;
  background: #0000006b;
  z-index: 99999999;
  overflow: hidden;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup_loginIn.active{
  width: 100vw;

}
.popup_loginIn_body{
  width: 600px;
  height: 450px;
  border-radius: 20px;
  background: #FFFFFF;
  position: relative;
  display: flex;
  padding: 68px 85px;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
}
.popup_loginIn_body_header{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  
}
.errorLog{
  text-align: center;
  color: red;
  display: none;
}
.errorLog.active{
  display: block;
}
.popup_loginIn_body_header h5{
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #1D1D1D;

}
.popup_loginIn_body_header img{
  width: 99px;
  height: 36px;

}
.popup_loginIn_input_box{
  /* width: 100%; */
  border: 1px solid #C8C8C8;
  height: 53px;
  border-radius: 6px;
  padding: 20px 30px;

}
.popup_loginIn_input_box input{
  all: unset;
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

}
.popup_loginIn_body_button{
  width: 100%;
  background: #0A1420;
  height: 49px;
  padding: 14px 30px 14px 30px;
  border-radius: 7px;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.exit{
    width: 15px;
    height: 15px;
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}
.header_menu_criete{
  border: 1px solid #0A1420;
  padding: 14px 20px;
  border-radius: 7px;

  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  background: none;

}




.slidePreviev{
  display: flex;
  align-items: center;
  gap: 5px;
}


.right{
  content: "";
  display: block;
  width: 0; 
  height: 0;
  border-top: 5px solid transparent;
  border-left: 10px solid #000;
  border-bottom: 5px solid transparent;
  cursor: pointer;

}
.right.edge{
  opacity: 0.5;
}
.left{
  display: block;
  width: 0; 
  height: 0;
  border-top: 5px solid transparent;
  border-right: 10px solid #000;
  border-bottom:5px solid transparent;
  cursor: pointer;
}
.left.edge{
  opacity: 0.5;
}



:root {
  --background-gradient: linear-gradient(178deg, #ffff33 10%, #3333ff);
  --gray: #34495e;
  --darkgray: #2c3e50;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: #0a1420;
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.header_lists {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: .25em;
  overflow: hidden;
}
/* Arrow */
.header_lists::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #DEDEDE;
  transition: .25s all ease;
  pointer-events: none;
}
/* Transition */
.header_lists:hover::after {
  color: #f39c12;
}


.printPopup{
  /* position: absolute; */
  display: none;
  width: 100%;
  height: 100%;
}

@media print {
  *{
     margin: 0;
     padding: 0; 
  }
  table,thead,tbody{
    width: 100%;
  }
  .printPopup{
    display:contents;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .imgPrint{
    width: 100%;
    position:relative;
    top: 100px;
    left: 0;
  }
  .titlePrint{
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

  }
  .textPrint{
    font-weight: bold;
    color: red;
    z-index: 99;
    position:relative;
    left: 0;
    width: 100%;
    height: auto;
    bottom: -100px;
  }

}