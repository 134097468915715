.proect{
    margin-bottom:50px;
}
.proect_no{
    display: flex;
    gap: 14px;
    margin: 35px 0px;


    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}
.proect h3{
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 25px;
}
.proect_name_input{
    border: 1px solid #0A1420;
    padding: 20px;
    border-radius: 6px;
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.proect_name_input input{
    all:unset;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;

}
.proect_name_input img{
    cursor: pointer;
}
.proect_name_input img:hover{
    transform: scale(1.1);
}

.proect_chek_item{

}
.proect_chek_item input{
    all:unset;
}
.proect_chek_item label{
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #616161;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    max-width: 180px;
    padding: 15px;
    border: 1px solid #0A1420;
    border-radius: 6px;
    cursor: pointer;
    background: #F3F3F3;
    text-wrap: nowrap;
}
.proect_chek_item label img{
    width: 10px;
    height: 10px;
    display: none;
}
.proect_chek_item input:checked+label img{
    display: block;
}
.proect_chek_item input:checked+label{
    background: none;

}
.proect_chek_items{
    display: flex;
    align-items: center;
    gap: 22px;
    margin-top: 25px;
}
.proect_makets{
    margin: 35px 0px;
}
.proect_makets input{
    
}
/*  */
.input-file-row {
	display: flex;
    gap: 22px;
}
.input-file {
	position: relative;
	display: inline-block;
}
.input-file span {
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
	font-size: 14px;
	vertical-align: middle;
	text-align: center;
	border-radius: 4px;
	background-color: #F3F3F3;
	line-height: 22px;
	height: 340px;
  width: 215px;
	padding: 10px 20px;
	box-sizing: border-box;
	border: none;
	margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
	transition: background-color 0.2s;
  gap: 20px;
}
.input-file span img{
    transform: rotatez(45deg);
    width: 31px;
    height: 31px;
}
.input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
.span{
    position: fixed;
    top: 20px;
    background: #fff;
    padding: 20px 30px;
    border-radius: 7px;
    border: 2px solid #000;
    display: none;
    opacity: 0;
    transition: 300ms;
}
.span.active{
    right:  20px;
    opacity: 1;

    display: block;
}
/* Focus */
/* .input-file input[type=file]:focus + span {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
} */
 
/* Hover/active */
/* .input-file:hover span {
	background-color: #59be6e;
}
.input-file:active span {
	background-color: #2E703A;
}
  */
/* Disabled */
.input-file input[type=file]:disabled + span {
	background-color: #eee;
}
 
/* Список c превью */
.input-file-list {
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
}
.input-file-list-item {
	height: 340px;
    width: 215px;
    vertical-align: top;
    position: relative;
    /* display: flex; */
    justify-content: center;
    border: 1px solid #E4E4E4;
    overflow: auto;
}
.input-delete{
    color: red;
    position: absolute;
    right: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
    transition: 300ms;
}
.input-delete:hover{
    transform: scale(1.2);
}
.input-file-list-item img {
	
    width: 100%;
}
.input-file-list-name {
	text-align: center;
	display: block;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
}
.input-file-list-remove {
	color: #fff;
	text-decoration: none;
	display: inline-block;
	position: absolute;
	padding: 0;
	margin: 0;
	top: 5px;
	right: 5px;
	background: #ff0202;
	width: 16px;
	height: 16px;
	text-align: center;
	line-height: 16px;
	border-radius: 50%;
}
.proect_head{
    display: flex;
    gap: 50px;
}
.proect_prev {
    width: 500px;
}
.proect_prev .input-file {
    width: 100%;
}
.proect_prev .input-file span {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 100%;
    
}
.proect_opis textarea{
    margin: 20px 0px;
    width: 30%;
}
.proect_dostup div{
    display: flex;
    gap: 30px;
    margin: 30px 0px;
}
.proect_link .proect_name_input{
    margin: 30px 0px;
}
.proect_data input{
    all:unset;
}
.proect_data div{
    padding: 30px 0px;
}
.proect_link input {
    width: 90%;
}




/*  */
.proces {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  .proces.stop{
    display: none;
  }
  .proces:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 3px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: proces 1.2s linear infinite;
  }
  @keyframes proces {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  







  .proect_makets section {
    padding: 2rem 0;
  }
 
  .proect_makets  label span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
  }
  
  .proect_makets input {
    display: none;
  }
  
  .proect_makets img {
    padding: 0;
    margin: 0;
  }
  
  .proect_makets .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .proect_makets .image {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  
  .proect_makets .image button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: white;
    background-color: lightcoral;
  }
  
  .proect_makets .image button:hover {
    background-color: red;
  }
  
  .proect_makets .image p {
    padding: 0 0.5rem;
    margin: 0;
  }
  
  .proect_makets .upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 3rem;
    color: white;
    background-color: green;
  }
  
  .proect_makets label:hover,
  .proect_makets .upload-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  .proect_makets .error {
    text-align: center;
  }
  
  .proect_makets .error span {
    color: red;
  }
  