.main{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: 1000ms;
}
.main .conteiner{
    min-height: 100vh;

}

.main_gallari{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    gap: 28px;
    padding: 15px 0px;
    padding-bottom: 50px;
    padding-top: 50px;
    transition: 300ms;
    position: relative;

}

.main_box {
    width: 193px;
    height: 49px;
    /* padding: 14px 30px 14px 30px; */
    border-radius: 8px;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F3F3;
    cursor: pointer;
    margin: 20px auto;
}
.main_box:hover{
    transform: scale(1.1);
}
.search{
    width: -webkit-fill-available;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px;
    background: #fff;
    justify-content: space-evenly;
    align-items: center;
}
.search_failed{
    width: 90%;
    border: 0; 
    height: 100%;
}
.search_failed:focus {
    outline: none;
}
.search_img{
    width: auto;
    height: 20px;
    padding: 2px;
}
.search_failed:focus + .search_img {
    
}
.filter{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.filter_chek{
    display: flex;
    justify-content: center;
    gap: 5px;

}
.filter_chek_item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 15px;
    gap: 10px;
    background: #F3F3F3;
    border-radius: 8px;
    border:none;
    text-wrap: nowrap;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #0A1420;
    cursor: pointer;
    transition: 300ms;
}
.filter_chek_item.activ_chek{
    border: 1px solid #0A1420;
    border-radius: 8px;
    background:#fff;
}
.filter_chek_item:hover{
    transform: scale(0.9);
}