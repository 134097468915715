@media only screen and (max-width : 1600px) {
	.container{
        width: 1400px;
    }
    

}
@media only screen and (max-width : 1400px) {
    .main_gallari{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .container{
        width: 1100px;
    }
    .filter_chek_item {
        
        padding: 10px 12px;
        font-size: 12px;
        line-height: 119%;
    }

}
@media only screen and (max-width : 1100px) {
    .container{
        width: 830px;
    }
    .preview_text {
        font-weight: 600;
        font-size: 24px;
        line-height: 23px;
    }
    .preview_img_box {
        height: 245px;
    }
    .main_gallari {
        grid-template-columns: 1fr 1fr;
    }

}
@media only screen and (max-width : 830px) {
    .preview_img_box {
        height: 245px;
    }
    .preview_text {
        font-size: 18px;
        line-height: 17px;
    }
    .miniShow_img_box {
        width: 100%;
        height: 100px;
        overflow: hidden;
    }
    .container{
        width: 600px;
    }
    .header_menu_criete {
        padding: 10px 15px;
        font-size: 12px;
    }
    .filter {
      
        flex-direction: column;
    }
}
@media only screen and (max-width : 600px) {
    .main_gallari {
        grid-template-columns: 1fr 1fr;
    }
    .right_gallery {
       padding: 0px;
        
    }
    .container{
        width: 470px;
    }
    .entrance {
        
        padding: 6px 10px;
    }
    .header_menu_criete {
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
    }
    .filter_chek {
       
        flex-wrap: wrap;
    }
    .search {
        margin: auto;
    }
    .switch-on::after {
        left: 29px;

    }

}
@media only screen and (max-width : 475px) {
    .main_gallari {
        grid-template-columns: 1fr;
    }
    .container{
        width: 100%;
    }
    .header {
 
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .switch-on::after {
        left: 42px;
    }
    .search {
        width: 80%;
    }
}

#header {
	width:100%;
}
#header img {
	max-width:100%;
}
#content {
	max-height:850px;
	height:850px;
	max-width:100%;
	text-align: center;
	padding-top:20px;
	box-sizing: border-box;
}
#content img {
	max-height:100%;
	max-width:100%;
}
#footer {
	text-align: center;
	padding:20px;
	bottom:0;
	font-size:18px;
}
#text_left {
	float:left;
}
#text_right {
	float:right;
}