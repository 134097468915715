.maket_changes{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000050;
    transform: scale(0);
    transition: 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.maket_changes.active{
    transform: scale(1);

}
.maket_changes_form{
    background: #fff;
    padding: 25px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

}