@import url("/src/font/museo/stylesheet.css");




*{
    margin: 0;
    padding: 0;
    transition: 150ms;
}
body{
    font-family: 'Museo Sans Cyrl';
}
.container{
    width: 1600px;
    margin: 0px auto;
}
#tr-popup{
    display: none;
}
#tr-popup[data-hidden="true"] {
    display: none;
}

button{
    cursor: pointer;
}
a{
    text-decoration: none;
    color: #131B28;
}
.flex{
    display: flex;
    gap: 20px;
}
.upWind{
    background: #131B28;
    border-radius: 30px;
    display: flex;
    position: fixed;
    width: 20px;
    height: 20px;
    bottom: 1%;
    padding: 10px;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    right:  15px;
    transition: 500ms;
}
.upWind:hover .upWind_text{
    color: #FBFF3F;
}
.upWind:hover{
    box-shadow: 0px 0px 15px #FBFF3F;
    
}
.upWind_img{
    transition: 300ms;
    width: 20px;
    height: 20px;
    position: absolute;
    
    filter: brightness(0.5);
}
.upWind:hover .upWind_img{
    width: 0px;
}
.upWind_img2{
    transition: 300ms;
    width: 0px;
    height: 20px;
    
    filter: brightness(0.5);
}
.upWind:hover .upWind_img2{
    width: 20px;
}
.upWind_text{
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 40px;
    color: #FFFFFF;
}

.massege{
    position: fixed;
    top: 20px;
    right: 20px;
    width: max-content;
    height: auto;
    padding: 20px;
    background: #FFFFFF;
    border: 2px solid #131B28;
    border-radius: 5px;
    cursor: pointer;
}

