@font-face {
    font-family: 'MuseoSansCyrl-300';
    src: url('MuseoSansCyrl-300.eot');
    src: local('☞MuseoSansCyrl-300'), local('MuseoSansCyrl-300'),
        url('MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-300.woff2') format('woff2'),
        url('MuseoSansCyrl-300.woff') format('woff'),
        url('MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl-100Italic';
    src: url('MuseoSansCyrl-100Italic.eot');
    src: local('☞MuseoSansCyrl-100Italic'), local('MuseoSansCyrl-100Italic'),
        url('MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-100Italic.woff2') format('woff2'),
        url('MuseoSansCyrl-100Italic.woff') format('woff'),
        url('MuseoSansCyrl-100Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-300Italic';
    src: url('MuseoSansCyrl-300Italic.eot');
    src: local('☞MuseoSansCyrl-300Italic'), local('MuseoSansCyrl-300Italic'),
        url('MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-300Italic.woff2') format('woff2'),
        url('MuseoSansCyrl-300Italic.woff') format('woff'),
        url('MuseoSansCyrl-300Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-100';
    src: url('MuseoSansCyrl-100.eot');
    src: local('☞MuseoSansCyrl-100'), local('MuseoSansCyrl-100'),
        url('MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-100.woff2') format('woff2'),
        url('MuseoSansCyrl-100.woff') format('woff'),
        url('MuseoSansCyrl-100.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl-900Italic';
    src: url('MuseoSansCyrl-900Italic.eot');
    src: local('☞MuseoSansCyrl-900Italic'), local('MuseoSansCyrl-900Italic'),
        url('MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-900Italic.woff2') format('woff2'),
        url('MuseoSansCyrl-900Italic.woff') format('woff'),
        url('MuseoSansCyrl-900Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('MuseoSansCyrl-500.eot');
    src: local('☞MuseoSansCyrl-500'), local('MuseoSansCyrl-500'),
        url('MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-500.woff2') format('woff2'),
        url('MuseoSansCyrl-500.woff') format('woff'),
        url('MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl-500Italic';
    src: url('MuseoSansCyrl-500Italic.eot');
    src: local('☞MuseoSansCyrl-500Italic'), local('MuseoSansCyrl-500Italic'),
        url('MuseoSansCyrl-500Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-500Italic.woff2') format('woff2'),
        url('MuseoSansCyrl-500Italic.woff') format('woff'),
        url('MuseoSansCyrl-500Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-700Italic';
    src: url('MuseoSansCyrl-700Italic.eot');
    src: local('☞MuseoSansCyrl-700Italic'), local('MuseoSansCyrl-700Italic'),
        url('MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-700Italic.woff2') format('woff2'),
        url('MuseoSansCyrl-700Italic.woff') format('woff'),
        url('MuseoSansCyrl-700Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-700';
    src: url('MuseoSansCyrl-700.eot');
    src: local('☞MuseoSansCyrl-700'), local('MuseoSansCyrl-700'),
        url('MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-700.woff2') format('woff2'),
        url('MuseoSansCyrl-700.woff') format('woff'),
        url('MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl-900';
    src: url('MuseoSansCyrl-900.eot');
    src: local('☞MuseoSansCyrl-900'), local('MuseoSansCyrl-900'),
        url('MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-900.woff2') format('woff2'),
        url('MuseoSansCyrl-900.woff') format('woff'),
        url('MuseoSansCyrl-900.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

