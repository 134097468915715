.preview{
    width: 100%;
    /* padding: 10px; */
    /* background: #fff; */
    border-radius: 15px;
    text-decoration: none;
    cursor: pointer;
    transition: 500ms;
    /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); */
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}
.preview:hover{

    transform: scale(1.05);
    /* box-shadow: 0px 2px 8px rgba(0, 0, 0); */
}
.preview_img{
    border-radius: 20px;
    border:none;
    width: 100%;
    height: 250px;
}
.preview_text{
    /* font-family: 'Inter'; */
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 130%;
color: #0A1420;
}
.eye {
    filter: invert(1);
}
.preview_img_box{
    height: 250px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
}
.preview_list{
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 7px;
}
.preview_list-item{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
    padding: 2px 6px 2px 6px;
    /* background: #F3F3F3; */

}
.preview_menu{
    position: absolute;
    opacity: 0;
    transition: 300ms;
    padding: 5px;
    right: -23px;
    top: 0px;
    height: 233px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.preview:hover .preview_menu{
    opacity: 1;

}
.preview_menu_opt{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    background: #0A1420;
    border-radius: 50%;
}
.preview_menu_opt img{
    max-width: 22px;
    max-height: 22px;
}
.preview_menu_opt span{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: aliceblue;
    position: absolute;
    right: 0px;
    opacity: 0;
    text-align: right;
    width: max-content;
    text-shadow: -1px 4px 5px black;
    transition: 300ms;
    pointer-events: none;
}
.preview_menu_opt:hover  span{
    right: 68px;
    opacity: 1;
}
.preview_delete{
    position: absolute;
    top: 5px;
    left: 16px;
    color: aliceblue;
    opacity: 0;
    display: flex;
    align-items: center;
}
.preview:hover .preview_delete{
    opacity: 0.5;
}
.preview_delete:hover {
    color: red;
    opacity: 1 !important;

}



.close-container{
    position: relative;
    margin: auto;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  
  .leftright{
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 24px;
    background-color: #F4A259;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all .3s ease-in;
  }
  
  .rightleft{
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 24px;
    background-color: #F4A259;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all .3s ease-in;
  }
  
  .close-container label{
    color: red;
    font-family: Helvetica, Arial, sans-serif; 
    font-size: .6em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all .3s ease-in;
    opacity: 0;
  }
  .close{
    margin: 50px 0 0 0px;
    position: absolute;
    color: red;
    text-align: center;
  }
  
  .close-container:hover .leftright{
    transform: rotate(-45deg);
    background-color: #F25C66;
  }
  .close-container:hover .rightleft{
    transform: rotate(45deg);
    background-color: #F25C66;
  }
  .close-container:hover label{
    opacity: 1;
  }
  
  